import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="header-content">
          <h1>FestaAI</h1>
          <nav>
            <ul>
              <li><a href="#home">Home</a></li>
              <li><a href="#vision">Our Vision</a></li>
              <li><a href="#about">About Us</a></li>
              <li><a href="#contact">Contact</a></li>
            </ul>
          </nav>
        </div>
      </header>

      <main>
        <section id="home" className="hero">
          <h2>Welcome to FestaAI</h2>
          <p className="tagline">Innovating the future of mobile experiences with AI</p>
          <a href="#vision" className="cta-button">Learn More</a>
        </section>

        <section id="vision">
          <h2>Our Vision</h2>
          <p>At FestaAI, we're passionate about creating cutting-edge mobile solutions powered by artificial intelligence. We are hard at work developing innovative apps that will revolutionize the way you interact with your mobile devices.</p>
          <div className="coming-soon">
            <h3>Coming Soon: FestaAI App</h3>
            <p>Our flagship mobile application is under development. Stay tuned for updates!</p>
            <a href="https://forms.gle/sZacYWwxQqk3RnQn6" target="_blank" rel="noopener noreferrer" className="waitlist-button">Join Waitlist</a>
          </div>
        </section>

        <section id="about">
          <h2>About Us</h2>
          <p>FestaAI is committed to pushing the boundaries of what's possible in mobile technology, with a focus on creating intuitive, AI-driven experiences.</p>
        </section>

        <section id="contact">
          <h2>Get in Touch</h2>
          <p>Interested in our upcoming projects or want to collaborate? We'd love to hear from you!</p>
          <a href="https://forms.gle/nQsSTbWj1uY7zcRs8" target="_blank" rel="noopener noreferrer" className="contact-button">Contact Us</a>
        </section>
      </main>

      <footer>
        <p>&copy; 2024 FestaAI. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
